.comments-container.closed,
.comments-container .unsubscribe,
.comments-expanded .comments-expand { 
    display:none; 
}
.comments-expanded .comments-container { 
    display:block; 
}
.comments-expanded .comment-count-title { 
    margin-top: 40px; 
}
.media.comment .comment-body .comment-user .avatar{
    margin-bottom: 5px;
}
.comment-user {
    margin-bottom: 5px;
    float: left;
    margin-right: 15px;
}

.media.comment .comment-body .avatar .tnt-svg.tnt-user {
    background: #848484;
    color: #FFF;
    font-size: 32px;
    padding: 4px 0;
    width: 40px;
}

.media.comment .comment-body .avatar .tnt-svg.tnt-user-secret {
    background: #848484;
    color: #FFF;
    font-size: 32px;
    padding: 4px 0 4px 3px;
    width:40px;
}

.media.comment .replies{
    margin:15px 0 0 0;
    padding:15px 0 15px 15px;
}

/* emoticons */
.blox_emoticons {
    width:19px;
    height:19px;
    margin:0px 2px;
    display: -moz-inline-box; /* Support for Firefox 2 */
    display:inline-block; /* FF3, Opera, Safari */
    overflow:hidden;
    text-indent:-9999px;
    background:url(../images/smiley_sprite.4019fb937b804c9ce9629a9616d59fdb.png) no-repeat;
    zoom:1;
    cursor:pointer;
    padding:0 1px 0 1px;
    }
.sprite-angry { 
    background-position: 0 0;
    height:18px; 
    } 
.sprite-ban {
    background-position:0 -29px;
    height:50px;
    width:56px;
    } 
.sprite-beam { 
    background-position: 0 -88px; 
    } 
.sprite-blink { 
    background-position: 0 -113px; 
    } 
.sprite-censored { 
    background-position: 0 -138px;
    width:34px; 
    } 
.sprite-cool { 
    background-position: 0 -161px; 
    } 
.sprite-crying { 
    background-position: 0 -186px;
    height:18px; 
    } 
.sprite-happybirthday { 
    background-position: 0 -209px;
    height:26px;
    width:80px; 
    } 
.sprite-huh { 
    background-position: 0 -240px; 
    } 
.sprite-innocent { 
    background-position: 0 -265px;
    height:22px; 
    } 
.sprite-lol { 
    background-position: 0 -292px; 
    } 
.sprite-offtopic { 
    background-position: 0 -317px;
    height:50px;
    width:45px; 
    } 
.sprite-rolleyes { 
    background-position: 0 -373px; 
    } 
.sprite-sad { 
    background-position: 0 -398px; 
    } 
.sprite-scared { 
    background-position: 0 -423px;
    height:20px; 
    } 
.sprite-sleep { 
    background-position: 0 -449px; 
    } 
.sprite-sleeping { 
    background-position: 0 -474px;
    height:25px; 
    } 
.sprite-smile { 
    background-position: 0 -505px; 
    } 
.sprite-sneaky { 
    background-position: 0 -530px; 
    } 
.sprite-spam { 
    background-position: 0 -555px;
    height:51px;
    width:50px; 
    } 
.sprite-tongue { 
    background-position: 0 -612px; 
    } 
.sprite-unsure { 
    background-position: 0 -637px; 
    } 
.sprite-whistling { 
    background-position: 0 -660px; 
    } 
.sprite-wink { 
    background-position: 0 -683px; 
    } 
.sprite-love { 
    background-position: 0 -708px;
    height:28px;
    width:21px; 
    } 
.sprite-yawn { 
    background-position: 0 -742px;
    height: 18px; 
    } 
.sprite-batman { 
    background-position:0 -771px;
    height:25px; 
    } 
.sprite-ninja { 
    background-position:0 -807px;
    height:18px;
    } 
.sprite-pirate { 
    background-position:0 -835px;
    height:18px;
    } 
.sprite-alien { 
    background-position:0 -864px;
    height:19px;
    } 
.sprite-thumbdown { 
    background-position:0 -914px;
    height:19px;
    } 
.sprite-thumbup { 
    background-position:0 -889px;
    height:19px;
    } 
.sprite-tongue_smile { 
    background-position:0 -940px;
    height:19px;
    } 
.sprite-ohmy {
    background-position:-24px -940px;
    height:19px;
    }